import * as Sentry from "@sentry/browser"

const appConfiguration = require("./config/config.json")

const sentryDns = appConfiguration.integrations?.sentry?.dsn
const sentryEnabled = appConfiguration.settings.enableSentryIntegration
const sentryEnvironment = appConfiguration.settings?.sentryOptionEnv
const sentryReplaySessionEnabled =
  appConfiguration.settings.sentrySessionReplayEnable

// default options
const options: Sentry.BrowserOptions = {
  dsn: sentryDns,
  environment: sentryEnvironment,
  release: `${process.env.GATSBY_APP_VERSION}`,
  integrations: [Sentry.replayIntegration()],
  beforeSend(event) {
    const userAgent = navigator.userAgent.toLowerCase()
    const blockedUserAgents = ["bytespider", "bytedance"]
    const isBlocked = blockedUserAgents.some(blockUserAgent =>
      userAgent.includes(blockUserAgent)
    )
    if (isBlocked) {
      return null
    }

    return event
  },
}

// session replay options
if (sentryReplaySessionEnabled) {
  options.replaysSessionSampleRate =
    appConfiguration.settings.sentrySessionReplaySampleRate / 100
  options.replaysOnErrorSampleRate =
    appConfiguration.settings.sentrySessionReplayOnErrorSampleRate / 100
}

if (sentryEnabled) {
  Sentry.init(options)
  console.log("=====Init Sentry Config=====")
}
