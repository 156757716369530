import {
  Box,
  BoxProps,
  ChakraProvider,
  Flex,
  useInterval,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import { nanoid } from "@reduxjs/toolkit"
import { AnimatePresence, motion, PanInfo } from "framer-motion"
import React, { useState } from "react"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import ArrowNavigation from "./ArrowNavigation"
import "./Carousel.scss"
import Indicator from "./Indicator"

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exiting: { opacity: 0 },
}

const dragThreshold = 100

export interface CarouselImageProps {
  src: string
  alt?: string
  showthumbnails?: "0" | "1"
  srcthumbnail: string
}

export interface IndicatorStyleProps extends BoxProps {
  borderFocusColor: string
}

export interface WidthProps {
  md?: string
  lg?: string
  xl?: string
}

interface Props {
  images: CarouselImageProps[]
  width: string | number
  height: string | number
  delay?: number
  carouselStyle?: BoxProps
  circleStyle?: IndicatorStyleProps
  thumbnailStyle?: IndicatorStyleProps
  thumbnailIndicatorSize?: WidthProps
  mobileThumbnailType: string
  config: AppEntityHydrated
}

const Carousel: React.FC<Props> = ({
  images,
  width,
  height,
  delay = 0,
  carouselStyle = {},
  circleStyle = { borderColor: "white", borderFocusColor: "#2AAAE1" },
  thumbnailStyle = {
    borderColor: "#2AAAE1",
    borderFocusColor: "#1C95C9",
  },
  thumbnailIndicatorSize = { md: "80px" },
  mobileThumbnailType,
  config,
}) => {
  const displayableImages = images.filter(
    item => Number(item.showthumbnails) === 1
  )
  const firstImageIndex = 0
  const lastImageIndex = displayableImages.length - 1
  const [currentIndex, setCurrentIndex] = useState(0)
  const { src, alt } = displayableImages[currentIndex]

  const handleNext = () => {
    setCurrentIndex(index => {
      if (index === displayableImages.length - 1) {
        return 0
      }
      return index + 1
    })
  }

  const selectImage = (index: number) => {
    setCurrentIndex(index)
  }

  const paginate = (newDirection: number) => {
    setCurrentIndex(prevIndex => {
      let newIndex = prevIndex + newDirection

      if (newIndex < 0) {
        return lastImageIndex
      } else if (newIndex >= displayableImages.length) {
        return firstImageIndex
      }
      return newIndex
    })
  }

  useInterval(() => {
    if (delay > 0) {
      handleNext()
    }
  }, delay)

  const handleDragEnd = (_event: MouseEvent | TouchEvent, info: PanInfo) => {
    const offsetX = info.offset.x

    if (offsetX > dragThreshold || (offsetX > 0 && offsetX < dragThreshold)) {
      paginate(-1)
    } else if (
      offsetX < -dragThreshold ||
      (offsetX < 0 && offsetX > -dragThreshold)
    ) {
      paginate(1)
    } else {
      paginate(0)
    }
  }

  const theme = makeTheme(config)

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" p={4} className="s__carousel">
        <Box
          aria-label="carousel-container"
          className="s__carousel-container"
          zIndex={10}
          maxWidth={width}
          width={width}
          height={height}
          backgroundColor="#fff"
          maxHeight={height}
          display="flex"
          align="center"
          overflow="hidden"
          alignItems={"center"}
          justifyContent={"center"}
          {...carouselStyle}
          position="relative"
        >
          <AnimatePresence initial={false}>
            <motion.img
              id={`carousel-image-${nanoid()}-${currentIndex}`}
              key={`carousel-image-${nanoid()}-${currentIndex}`}
              initial="hidden"
              animate="visible"
              exit="exiting"
              src={src}
              alt={alt}
              width="100%"
              height="100%"
              variants={variants}
              style={{ objectFit: "cover", objectPosition: "center" }}
              drag="x"
              onDragEnd={handleDragEnd}
              dragConstraints={{ left: 0, right: 0 }}
              transition={{
                duration: 0,
              }}
              className="fade-animation"
            />
          </AnimatePresence>
          <ArrowNavigation paginate={paginate} />
        </Box>

        <Indicator
          displayableImages={displayableImages}
          currentIndex={currentIndex}
          selectImage={selectImage}
          thumbnailStyle={thumbnailStyle}
          circleStyle={circleStyle}
          thumbnailIndicatorSize={thumbnailIndicatorSize}
          mobileThumbnailType={mobileThumbnailType}
        />
      </Flex>
    </ChakraProvider>
  )
}

export default Carousel
