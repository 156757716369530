import { Box, Circle, Flex, Image, useMediaQuery } from "@chakra-ui/react"
import React from "react"
import { CarouselImageProps, IndicatorStyleProps, WidthProps } from "./Carousel"

interface IndicatorProps {
  displayableImages: CarouselImageProps[]
  currentIndex: number
  thumbnailStyle: IndicatorStyleProps
  circleStyle: IndicatorStyleProps
  thumbnailIndicatorSize: WidthProps
  mobileThumbnailType: string
  selectImage: (index: number) => void
}

interface Props {
  image: CarouselImageProps
  currentIndex: number
  index: number
  thumbnailStyle: IndicatorStyleProps
  circleStyle: IndicatorStyleProps
  thumbnailIndicatorSize: WidthProps
  mobileThumbnailType: string
  selectImage: (index: number) => void
}

const IndicatorItem: React.FC<Props> = ({
  image,
  currentIndex,
  index,
  selectImage,
  thumbnailStyle,
  circleStyle,
  thumbnailIndicatorSize,
  mobileThumbnailType,
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 768px)")
  const { md, lg, xl } = thumbnailIndicatorSize
  const isActive = currentIndex === index

  const showCircleThumbnail = !isDesktop && mobileThumbnailType === "circle"

  const {
    borderColor: mobileBorderColor,
    borderFocusColor: mobileBorderFocusColor,
    ...mobileCircleStyle
  } = circleStyle

  const { borderColor, borderFocusColor, ...restThumbnailStyle } =
    thumbnailStyle

  if (showCircleThumbnail) {
    return (
      <Circle
        aria-label="point indicator"
        key={`circle-${index}`}
        data-testid={`test-carousel-thumbnail-${index}`}
        size={2}
        mx={2}
        cursor="pointer"
        {...mobileCircleStyle}
        bgColor={isActive ? mobileBorderFocusColor : mobileBorderFocusColor}
        onClick={() => selectImage(index)}
      />
    )
  }

  return (
    <Box
      className="s__indicator-image"
      aria-label="indicator-image"
      w={{ md, lg, xl }}
      h={{ md, lg, xl }}
      key={`image-${index}`}
      data-testid={`test-image-carousel-thumbnail-${index}`}
      mr={2}
      cursor="pointer"
      borderWidth={2}
      bgColor="white"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      {...restThumbnailStyle}
      borderColor={isActive ? borderFocusColor : borderColor}
    >
      <Image
        className="s__indicator-thumbnail"
        src={image.srcthumbnail}
        alt={image.alt}
        objectFit="cover"
        width="100%"
        height="100%"
        onClick={() => selectImage(index)}
      />
    </Box>
  )
}

const Indicator: React.FC<IndicatorProps> = ({
  displayableImages,
  currentIndex,
  selectImage,
  thumbnailStyle,
  circleStyle,
  thumbnailIndicatorSize,
  mobileThumbnailType,
}) => {
  return (
    <Flex
      mt={4}
      className="s__indicator-wrapper"
      aria-label="indicator-wrapper"
      p={0}
      justify={{ base: "center", md: "flex-start" }}
    >
      {displayableImages.map((image, index) => (
        <IndicatorItem
          key={`indicator-${index}`}
          image={image}
          currentIndex={currentIndex}
          selectImage={selectImage}
          index={index}
          thumbnailStyle={thumbnailStyle}
          circleStyle={circleStyle}
          thumbnailIndicatorSize={thumbnailIndicatorSize}
          mobileThumbnailType={mobileThumbnailType}
        />
      ))}
    </Flex>
  )
}

export default Indicator
