import { ChakraProvider, HStack } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import SocialMediaLink from "~/components/SocialMediaLInk"
import {
  renderShortCode,
  ShortCodeParser,
  ShortCodeStateEntities,
} from "../replaceShortCodes"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("socialMediaButtons", html, args => {
    const { justify = "center", align = "center" } = args.attrs.named

    const appConfig = state.appConfig!
    const { facebookUrl, instagramUrl, twitterUrl, youtubeUrl } = appConfig
    const theme = makeTheme(appConfig)

    return renderShortCode({
      type: "socialMediaButtons",
      args,
      component: (
        <ChakraProvider theme={theme}>
          <HStack spacing={4} align={align} justify={justify}>
            {facebookUrl && (
              <SocialMediaLink
                icon={FaFacebook}
                link={facebookUrl}
                color="gray.600"
              />
            )}

            {instagramUrl && (
              <SocialMediaLink
                icon={FaInstagram}
                link={instagramUrl}
                color="gray.600"
              />
            )}

            {twitterUrl && (
              <SocialMediaLink
                icon={FaTwitter}
                link={twitterUrl}
                color="gray.600"
              />
            )}

            {youtubeUrl && (
              <SocialMediaLink
                icon={FaYoutube}
                link={youtubeUrl}
                color="gray.600"
              />
            )}
          </HStack>
        </ChakraProvider>
      ),
    })
  })
}
